const prod = {
  url: {
    WEB_DOMAIN: 'https://sparkapt.com/',
    DOMAIN_URL: 'https://api.sparkapt.com',
    REACT_APP_BACKEND_BASE_URL: 'https://api.sparkapt.com/',
    REACT_APP_STRIPE_KEY: 'pk_live_51IEUJMK4AkswaJ3TbpES2jrJMEBAWuNZQr0AJqHe3uXHRysu1HQ3Aj9gTORco0g8vma07Gso9Q1KC0XpLRWvMnVr00fVdAz9TB',
    //REACT_APP_STRIPE_KEY: 'pk_test_51IEUJMK4AkswaJ3TY6iRPeBEuryv7Uw7dQ2wM0ZcMptloAhWPN1Sx39CIWYECz1cfsY1KUGjXCb9wUZfeZ68HT8K004OnGxIQQ',
    ACTION_CABLE_URL: "wss://api.sparkapt.com/cable"
  },
  ga_id: 'UA-226941692-1',
  CAPTCHA_SITE_KEY: "6LcGL6YmAAAAAFXcx1xMZn77U7Q-yYS_PS3gpRzE"
};

const dev = {
  url: {
    WEB_DOMAIN: 'http://localhost:3000/',
    DOMAIN_URL: 'https://sparkgql.compli.tech',
    REACT_APP_BACKEND_BASE_URL: 'https://sparkgql.compli.tech',
    // REACT_APP_BACKEND_BASE_URL: 'http://localhost:3002/',
    REACT_APP_STRIPE_KEY:
      'pk_test_51IEUJMK4AkswaJ3TY6iRPeBEuryv7Uw7dQ2wM0ZcMptloAhWPN1Sx39CIWYECz1cfsY1KUGjXCb9wUZfeZ68HT8K004OnGxIQQ',
  },
  ga_id: 'UA-226941692-1',
  CAPTCHA_SITE_KEY: "6LcGL6YmAAAAAFXcx1xMZn77U7Q-yYS_PS3gpRzE"
};

export default process.env.NODE_ENV === 'development' ? dev : prod;

