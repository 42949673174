import React, { useState, useReducer, useEffect } from 'react';
import { useRecoilState } from 'recoil'
import {
	allPropertiesAtom,
	selectedPropertiesAtom,
	propertiesPaginationInfoAtom,
	propertiesPageInfoAtom,
	selectedPropertyArrayAtom,
	propertiesFilterAtom
} from '../../../store/atoms/PropertiesAtom';
import { useHistory, useParams } from 'react-router-dom';
// import config from '@config';
import Spinner from '../../../components/Spinner';
import ShowProperty from './ShowProperty';
import SendReport from './SendReport';
import PropertyList from './List/List';
import PropertyMap from './Map/Map';
import {
  Row,
  Col,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Tooltip,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
// import { NoData } from "@assets";
import { PROPERTY_LIST_VIEW, PROPERTIES_MARKERS, DRAFT_REPORT } from '@api';
import { useMutation, useQuery } from '@apollo/client';
import FilterBar from './FilterbarNew/FilterBar';
import classnames from 'classnames';
import SidebarRight from '../../../components/SidebarRight';
import { filterUnits, filterFloorPlans } from '@sparklib';
import { Icon } from '@assets';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

export default function Properties(props) {
  // const stripePromise = loadStripe(config.url.REACT_APP_STRIPE_KEY);
  const [properties, setProperties] = useRecoilState(allPropertiesAtom);
  const [selectedCityId, setSeletedCityId] = useState('');
  const [sidebarWidth, setSidebarWidth] = useState('550px');
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [property, setProperty] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [pageInfo, setPageInfo] = useRecoilState(propertiesPageInfoAtom);
  const [paginationInfo, setPaginationInfo] = useRecoilState(propertiesPaginationInfoAtom);
  const [globalPopertiesFilter, setGlobalPopertiesFilter] = useRecoilState(propertiesFilterAtom);
  const [selectedProperties, setSelectedProperties] = useRecoilState(selectedPropertiesAtom);
  const [selectedPropertyArray, setSelectedPropertyArray] = useRecoilState(selectedPropertyArrayAtom);
  const [selectedPropertiesFromMap, setSelectedPropertiesFromMap] = useState([]);
  const toggleReportModal = (tab) => {
    if (selectedProperties.length > 0) setSidebarState(!sidebarState);
  };
	const [openToolTip, setOpenToolTip] = useState(false);
  const [draftTitle, setDraftTitle] = useState('');
  const [draftModal, setDraftModal] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const draftToggle = () => setDraftModal(!draftModal);


  const history = useHistory();
  const noFilters = [
    {
      name: 'search',
      label: 'Search',
      value: '',
    },
    {
      name: 'city',
      label: 'City',
      value: '{}',
    },
    {
      name: 'neighborhoods',
      label: 'Neighborhoods',
      value: [],
    },
    {
      name: 'zipCodes',
      label: 'Zip Codes',
      value: [],
    },
    {
      name: 'minPrice',
      label: 'Min. Price',
      value: null,
    },
    {
      name: 'maxPrice',
      label: 'Max. Price',
      value: '',
    },
    {
      name: 'bed',
      label: 'Bedroom Size',
      value: [],
    },
    {
      name: 'bath',
      label: 'Bathrooms',
      value: '{}',
    },
    {
      name: 'sqFt',
      label: 'Size',
      value: '',
    },
    {
      name: 'unitFeatures',
      label: 'Unit Features',
      value: [],
    },
    {
      name: 'availFrom',
      label: 'Available From',
      value: null,
    },
    {
      name: 'yearFrom',
      label: 'Year Built From',
      value: null,
    },
    {
      name: 'yearTo',
      label: 'Year Built To',
      value: null,
    },
    {
      name: 'availTo',
      label: 'Available To',
      value: null,
    },
    {
      name: 'bonus',
      label: 'Bonus',
      value: null,
    },
    {
      name: 'minSend',
      label: 'Send Escort',
      value: null,
    },
    {
      name: 'escort',
      label: 'Escort',
      value: null,
    },
    {
      name: 'amenities',
      label: 'Amenities',
      value: [],
    },
    {
      name: 'policies',
      label: 'Policies',
      value: [],
    },
    {
      name: 'googleRating',
      label: 'Google Rating',
      value: null,
    },
    {
      name: 'propertyIds',
      label: 'Property Ids',
      value: null,
    },
    {
      name: 'renovatedFrom',
      label: 'Renovated After',
      value: null,
    },
  ];

  const filterReducer = (state, action) => {
    switch (action.type) {
      case 'set':
        setPaginationInfo({ ...paginationInfo, isFirstRequest: true });
        return state.map((filter) => {
          if (filter.name === action.name) {
            return { ...filter, value: action.value };
          } else {
            return filter;
          }
        });
      case 'reset':
        setPaginationInfo({ ...paginationInfo, isFirstRequest: true });
        return action.name.constructor === Array
          ? state.map((filter) => {
              let f = action.name.find((f) => f === filter.name);
              if (f) {
                return noFilters.find((filter) => filter.name === f);
              } else {
                return filter;
              }
            })
          : state.map((filter) => {
              if (filter.name === action.name) {
                return noFilters.find((filter) => filter.name === action.name);
              } else {
                return filter;
              }
            });
      case 'setAll':
        setPaginationInfo({ ...paginationInfo, isFirstRequest: true });
        return action.filters;
      case 'resetAll':
        setPaginationInfo({ ...paginationInfo, isFirstRequest: true });
        return noFilters;
      default:
        return state;
    }
  };

  const [filters, changeFilters] = useReducer(filterReducer, globalPopertiesFilter);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [shouldMapSearch, setShouldMapSearch] = useState(false);

	const [saveReportAsDraft] = useMutation(DRAFT_REPORT, {
    onCompleted: (data) => {
      if (
        data.draftReport.errors === null ||
        data.draftReport.errors.length === 0
      ) {
        toast(
          () => (
            <div>
              Draft report has been created successfully. Visit{" "}
              <a
                href="/my-recommendations"
                style={{ textDecoration: "underline" }}
              >
                Lists
              </a>{" "}
              to see your draft reports.
            </div>
          ),
          { type: "success" }
        );
        draftToggle();
      } else {
        toast(data.draftReport.message, { type: "error" });
      }
    },
	})

	function handleDraftReport(){
		if (selectedProperties.length > 0) {
      if (!draftTitle) {
        setTitleError(true);
        return;
      } 
      setTitleError(false);
			saveReportAsDraft({
				variables: {
					reportProperties: selectedProperties,
          title: draftTitle
				}
			})
      setSelectedProperties([]);
		} else {
			toast("Please select properties", { type: 'error'})
		}
	}

	useEffect(() => {
		setGlobalPopertiesFilter(filters)
	}, [filters])

  const getFilter = (filterName) => {
    const filterValue = filters.find(
      (filter) => filter.name === filterName
    )?.value;
    return filterValue;
    // return typeof filterValue === Array ? filterValue.join(', ') : filterValue;
  };

  const setFilter = ({ e, key = null }) => {
    if (e.target) {
      const { name, value } = e.target;
      changeFilters({ type: 'set', name: name, value: value || '' });
      //(filters) => ({ ...filters, [name]: value || '' }));
    } else {
      changeFilters({ type: 'set', name: key, value: e });
      // e.constructor === Array
      //   ? changeFilters({ type: 'set', name: key, value: e })
      //   : changeFilters({ type: 'set', name: key, value: e.label });
      //changeFilters((filters) => ({ ...filters, [key]: e.label }));
    }
  };

  function togglePropertySelection(property) {
    const result = selectedProperties.find(
      (p) => Number(p.propertyId) === Number(property.node.id)
    );

    if (result !== undefined) {
      setSelectedProperties((selectedProperties) =>
        selectedProperties.filter(
          (p) => Number(p.propertyId) !== Number(property.node.id)
        )
      );
    } else {
      setSelectedProperties((selectedProperties) => [
        ...selectedProperties,
        { node: { ...property, highlight: true } },
      ]);
    }
  }

  function toggleSelection(property) {
    const propertyObject = {};
    propertyObject.propertyId = Number(property.id);
    propertyObject.floorPlanId = null;
    propertyObject.typeDetailId = null;

    const result = selectedProperties.find(
      (p) => Number(p.propertyId) === Number(property.id)
    );
    if (result !== undefined) {
      let newArrayValue = selectedProperties.filter(
        (p) => Number(p.propertyId) != Number(property.id)
      );

      setSelectedProperties(newArrayValue);
      /* setSelectedPropertyArray((selectedPropertyArray) =>
        selectedPropertyArray.filter((p) => p.node.id !== property.id)
      ); */
    } else {
      setSelectedProperties([...selectedProperties, propertyObject]);
      /* setSelectedPropertyArray((selectedProperties) => [
        ...selectedPropertyArray,
        { node: { ...property, highlight: true } },
      ]); */
    }
  }

	function convertUTCToLocal(date) {
		if (!date) return null;
		var utcMoment = moment.utc(date);
		var localMoment = utcMoment.local();
		var localDatetimeString = localMoment.format();
		return localDatetimeString;
	}

  function queryVariables() {
    //setMapLoaded(false);
    return {
			propertyIds: selectedPropertiesFromMap.length > 0 ? selectedPropertiesFromMap : null,
      cityId: JSON.parse(getFilter('city'))?.value
        ? Number(JSON.parse(getFilter('city')).value)
        : null,
      search: getFilter('search') ? getFilter('search') : null,
      minRent: getFilter('minPrice') ? parseInt(getFilter('minPrice')) : null,
      maxRent: getFilter('maxPrice') ? parseInt(getFilter('maxPrice')) : null,
      availFrom: getFilter('availFrom') ? convertUTCToLocal(getFilter('availFrom')) : null,
      availTo: getFilter('availTo') ? convertUTCToLocal(getFilter('availTo')) : null,
      yearFrom: getFilter('yearFrom') ? getFilter('yearFrom').toString() : null,
      yearTo: getFilter('yearTo') ? getFilter('yearTo') : null,
      renovatedFrom: getFilter('renovatedFrom') ? getFilter('renovatedFrom').toString() : null,
      sqFeet: getFilter('sqFt') ? getFilter('sqFt') : null,
      escort: getFilter('escort') ? getFilter('escort') : null,
      sendEscort: getFilter('minSend') ? getFilter('minSend') : null,
      // zip: getFilter('zip') ? getFilter('zip') : null,
      bonus: getFilter('bonus') ? getFilter('bonus').toString() : null,
      bedroom:
        getFilter('bed') && getFilter('bed').length > 0
          ? getFilter('bed')
          : null,
      bathroom:
        getFilter('bath') && JSON.parse(getFilter('bath'))?.value
          ? JSON.parse(getFilter('bath')).value
          : null, //getFilter('bath').length > 0 ? getFilter('bath') : null,

      // pet: filters.selectedPet ? filters.selectedPet : null,
      neighborhoodIds:
        getFilter('neighborhoods') && getFilter('neighborhoods').length > 0
          ? getFilter('neighborhoods').map((opt) => Number(opt.value))
          : null,

      zipCodes:
        getFilter('zipCodes') && getFilter('zipCodes').length > 0
          ? getFilter('zipCodes').map((opt) => opt.value)
          : null,

      addons:
        getFilter('amenities') && getFilter('amenities').length > 0
          ? getFilter('amenities').map((opt) => opt.label)
          : null,
      policies:
        getFilter('policies') && getFilter('policies').length > 0
          ? getFilter('policies').map((opt) => opt.label)
          : null,
      unitFeatures: null,
        // getFilter('unitFeatures') && getFilter('unitFeatures').length > 0
        //   ? getFilter('unitFeatures').map((opt) => opt.label)
        //   : null,
      rating: getFilter('googleRating')
        ? getFilter('googleRating').toString()
        : null,
      orderBy: orderBy,
      onlyAvailable:
        (getFilter('bed') && getFilter('bed').length > 0) ||
        (getFilter('bath') && JSON.parse(getFilter('bath'))?.value) ||
        getFilter('minPrice') ||
        getFilter('maxPrice') ||
        getFilter('availFrom') ||
        getFilter('availTo') ||
        getFilter('sqFt') ||
        // getFilter('yearFrom') ||
        // getFilter('yearTo') ||
        (getFilter('unitFeatures') && getFilter('unitFeatures').length > 0)
          ? true
          : false,
    };
  }

	// const { loading: propertyLoading } = useSubscription(PROPERTIES_SUBSCRIPTIONS, {
	// 	variables: {
  //     ...queryVariables(),
  //     ...(paginationInfo.isPreviousPage
  //       ? {
  //           before: paginationInfo.isPreviousPage ? pageInfo?.startCursor : '',
  //           last: paginationInfo.pageSize,
  //         }
  //       : {
  //           first: paginationInfo.pageSize,
  //           after: paginationInfo.isNextPage ? pageInfo?.endCursor : '',
  //         }),
  //   },
	// 	shouldResubscribe: true,
	// 	skip: !shouldSearch,
	// 	onSubscriptionData: ({ subscriptionData: { data } }) => {
	// 		console.log(data)
	// 		setProperties(getFilteredProperies(data.propertiesSubscription.edges));
  //     setPageInfo(data.propertiesSubscription.pageInfo);
  //     setPaginationInfo({
  //       ...paginationInfo,
  //       isFirstRequest: false,
  //       currentPage: paginationInfo.isFirstRequest
  //         ? 1
  //         : paginationInfo.currentPage,
  //       pageSize: paginationInfo.pageSize,
  //       totalPage: Math.ceil(
  //         Number(data.propertiesSubscription.totalCount) / paginationInfo.pageSize
  //       ),
  //       isNextPage: false,
  //       isPreviousPage: false,
  //       hasNextPage: data.propertiesSubscription.pageInfo.hasNextPage,
  //       hasPreviousPage: data.propertiesSubscription.pageInfo.hasPreviousPage,
  //     });
  //     //setIsNextPage(false);
  //     //setIsPreviousPage(false);

  //     setShouldSearch(false);
	// 	},
	// 	onError: (e) => {
	// 		console.log(e, 'error');
	// 		setShouldSearch(false);
	// 	}
	// })

  const { loading: propertyLoading } = useQuery(PROPERTY_LIST_VIEW, {
    variables: {
      ...queryVariables(),
      ...(paginationInfo.isPreviousPage
        ? {
            before: paginationInfo.isPreviousPage ? pageInfo?.startCursor : '',
            last: paginationInfo.pageSize,
          }
        : {
            first: paginationInfo.pageSize,
            after: paginationInfo.isNextPage ? pageInfo?.endCursor : '',
          }),
    },
    skip: !shouldSearch,
    fetchPolicy: 'cache-and-network',
    /* optimistic(data) */
    onCompleted: (data) => {
      //setMapMarkers([]);
      setProperties(getFilteredProperies(data.properties.edges));
      setPageInfo(data.properties.pageInfo);
      setPaginationInfo({
        ...paginationInfo,
        isFirstRequest: false,
        currentPage: paginationInfo.isFirstRequest
          ? 1
          : paginationInfo.currentPage,
        pageSize: paginationInfo.pageSize,
        totalPage: Math.ceil(
          Number(data.properties.totalCount) / paginationInfo.pageSize
        ),
        isNextPage: false,
        isPreviousPage: false,
        hasNextPage: data.properties.pageInfo.hasNextPage,
        hasPreviousPage: data.properties.pageInfo.hasPreviousPage,
      });
      //setIsNextPage(false);
      //setIsPreviousPage(false);

      setShouldSearch(false);
    },
    onError: (e) => {
      console.log(e, 'error');
      setShouldSearch(false);
    },
  });

	function filterAvailableFloorPlans(floorPlans){
		if (floorPlans.length === 0) return false;
		else if (floorPlans.filter(fp => fp.isAvailable && (fp.maxRent || fp.rentMin)).length > 0) return true;
		return false;
	}

  function getFilteredProperies(rawProperties) {
    const filteredProperties = [];
		rawProperties.forEach((p) => {
			var property = filterUnits(p.node, getFilter);
			if (queryVariables()?.onlyAvailable && property?.floorPlans?.length > 0 || !queryVariables()?.onlyAvailable){
				filteredProperties.push({ node: property, originalNode: p.node })
			}
    });
    if (filteredProperties.length == 0) {
      toast('Uh oh, looks like there are no results that match your criteria! Try to play around or remove some filters to see if additional results appear. If you think there is a mistake, please message us with a description and a screenshot of your search filters and we’ll look into it! Thank you.', { type: 'info', autoClose: false, hideProgressBar: true });
    }
    return filteredProperties;
    // return filteredProperties.filter(p => filterAvailableFloorPlans(p.originalNode.floorPlans));
  }

  function getFilteredProperiesForMap(rawProperties) {
    const filteredProperties = [];
		rawProperties.forEach((p) => {
			if (filterAvailableFloorPlans(p.node.floorPlans)) filteredProperties.push({ node: p.node, originalNode: p.node })
    });
    return filteredProperties;
  }

  useEffect(() => {
    let selectedPropertyIds = _.uniq(
      selectedProperties.map((sp) => Number(sp.propertyId))
    );

    setSelectedPropertyArray((selectedPropertyArray) =>
      selectedPropertyIds.map((propertyId) => {
        let found = selectedPropertyArray.find(
          (sp) => Number(sp.node.id) === Number(propertyId)
        );
        let found1 = properties.find(
          (p) => Number(p.node.id) === Number(propertyId)
        );
        return found
          ? found
          : found1
          ? {
              ...found1,
              highlight: true,
            }
          : { node: property, highlight: true };
        // return found
        //   ? found
        //   : {
        //       ...properties.find(
        //         (p) => Number(p.node.id) === Number(propertyId)
        //       ),
        //       highlight: true,
        //     };
      })
    );
  }, [selectedProperties]);

  useEffect(() => {
    if (props.location.state) {
      let searchFilters = props.location.state;
      changeFilters({ type: 'setAll', filters: searchFilters });
    }
  }, []);

  const { loading: markersLoading } = useQuery(PROPERTIES_MARKERS, {
    variables: queryVariables(),
    skip: !shouldMapSearch,
    onCompleted: (data) => {
      setMapMarkers(getFilteredProperiesForMap(data.propertiesWithoutJoinData.edges));
      setShouldMapSearch(false);
      //setMapLoaded(true);
    },
    onError: (e) => {
      setShouldMapSearch(false);
    },
  });

  useEffect(() => {
    if (markersLoading === true) {
      setMapLoaded(false);
    }
  }, [markersLoading]);
  /* useEffect(() => {
    if (shouldMapSearch) {
      setMapMarkers([]);
    }
  }, [shouldMapSearch]); */
  useEffect(() => {
    if (paginationInfo.isNextPage === true) {
      setShouldSearch(true);
    }
    if (paginationInfo.isPreviousPage === true) {
      setShouldSearch(true);
    }
  }, [paginationInfo]);

  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      endCursor: '',
      hasNextPage: '',
      hasPreviousPage: '',
      startCursor: '',
    });
    setShouldSearch(true);
  }, [paginationInfo.pageSize]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
  });

  const [activeTab, setActiveTab] = useState(() => {
    const storedValue = localStorage.getItem('default_property_view');
    return storedValue === 'card' ? 'Map' : 'List';
  });

   useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let { id, operation } = useParams();

  const showProperty = (
    <ShowProperty
      property={property}
      queryVariables={queryVariables()}
      selectedProperties={selectedProperties}
      setSelectedProperties={setSelectedProperties}
      sidebarToggle={() => {
        setSidebarState(!sidebarState);
      }}
      {...props}
    />
  );
  const showSendList = (
    <SendReport
      selectedCityId={selectedCityId}
      selectedProperties={selectedProperties}
      selectedPropertyArray={selectedPropertyArray}
      removePropertySelection={(property) => togglePropertySelection(property)}
      sidebarToggle={() => {
        setSidebarState(!sidebarState);
      }}
      {...props}
    />
  );

  const [sidebarState, setSidebarState] = useState(id ? true : false);
  const [showInSidebar, setShowInSidebar] = useState('');

  useEffect(() => {
    if (sidebarState === true) {
      if (!id) {
        setSidebarWidth('1150px');
        setShowInSidebar(showSendList);
        history.push(`/` + props.path);
      } else {
        setSidebarWidth('1150px');
        if (property != null) {
          setShowInSidebar(showProperty);
        } else {
          // setSidebarState(false);
          // history.push(`/` + props.path);
          setShowInSidebar(showProperty);
        }
      }
    } else {
      setShowInSidebar('');
      setSidebarWidth('1150px');
      history.push(`/` + props.path);
    }
  }, [sidebarState, selectedPropertyArray]);

	useEffect(() => {
			changeFilters({ type: 'set', name: 'propertyIds', value: selectedPropertiesFromMap.length > 0 ? selectedPropertiesFromMap : null });
			setShouldSearch(true);
		// }
	}, [selectedPropertiesFromMap])

  return (
    <>
      {(propertyLoading || shouldSearch) && <Spinner />}
      <SidebarRight
        sidebarState={sidebarState}
        sidebarToggle={() => {
          setSidebarState(!sidebarState);
        }}
        sidebarContent={showInSidebar}
        sidebarWidth={sidebarWidth}
        sidebarButton={false}
      />

      <FilterBar
        filters={filters}
        changeFilters={changeFilters}
        getFilter={getFilter}
        setFilter={setFilter}
        shouldSearch={shouldSearch}
        setShouldSearch={setShouldSearch}
        shouldMapSearch={shouldMapSearch}
        setShouldMapSearch={setShouldMapSearch}
        // changeProgress={(progress) => setProgress(progress)}
        // isFilter={true}
        // selectedCityId={(selectedCityId) => setSeletedCityId(selectedCityId)}
      />

      <Row>
        <Col md='12' className='table-tabs lg pt-5 mt-5'>
          {(getFilter('clientName')) &&
            <>
              <div className='text-center pt-3'>
                Searching for <b>{getFilter('clientName')}</b>
              </div>
            </>
          }
          <div className="d-md-none my-2 pr-4 w-100 d-flex justify-content-sm-center align-items-center" id="Tooltip-select-properties">
            <a
              href='javascript:void(0)'
              className='ml-2 mr-3 pt-3'
              onClick={() => setSelectedProperties([])}
              style={{display: selectedPropertyArray.length > 0 ? 'inline-block' : 'none'}}
            >
              Clear Selections
            </a>
            <Button
              color='outline-primary text-white'
              className='btn-rounded btn-blue mt-3'
              // disabled={selectedPropertyArray.length > 0 ? false : true}
              onClick={toggleReportModal}
              // style={{ minWidth: '222px' }}
            >
              <Icon icon='paper-plane' />{' '}
              {(() => {
                switch (selectedPropertyArray.length) {
                  case 0:
                    return 'Select Properties';
                  case 1:
                    return 'Send 1 Property ';
                  default:
                    return `Send ${selectedPropertyArray.length} Properties`;
                }
              })()}
            </Button>
          </div>
          <Nav tabs className='pt-3 justify-content-lg-center align-items-center'>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'List' })}
                onClick={() => {
                  toggle('List');
                }}
              >
                <Icon icon='list' />{' '}
                <span className='d-none d-md-inline-block ml-2'>List</span>
              </NavLink>
            </NavItem>
            <NavItem style={{ borderTopRightRadius: 'var(--rounded-big)', overflow: 'hidden'}}>
              <NavLink
                className={classnames({ active: activeTab === 'Map' })}
                onClick={() => {
                  toggle('Map');
                }}
              >
                <Icon icon='square' />{' '}
                <span className='d-none d-md-inline-block ml-2'>Cards </span>
              </NavLink>
            </NavItem>

            <div
              className='w-auto d-none d-md-inline-block ml-3'
              id="Tooltip-select-properties"
            >
              <Button
                className='button-dashboard btn-blue'
                // disabled={selectedPropertyArray.length > 0 ? false : true}
                onClick={toggleReportModal}
              >
                <Icon icon='paper-plane' />{' '}
                {(() => {
                  switch (selectedPropertyArray.length) {
                    case 0:
                      return 'Select Properties';
                    case 1:
                      return 'Send 1 Property ';
                    default:
                      return `Send ${selectedPropertyArray.length} Properties`;
                  }
                })()}
              </Button>
              <a
                href='javascript:void(0)'
                className='ml-2'
                onClick={() => setSelectedProperties([])}
                style={{display: selectedPropertyArray.length > 0 ? 'inline-block' : 'none'}}
              >
                Clear Selections
              </a>
            </div>

          </Nav>

        {(localStorage.getItem('user_type') === 'user') &&
        <>
          <div
            style={{ position: 'absolute' }}
            className='table-tabs-button float-right w-auto d-none d-sm-inline-block'
						id="Tooltip-draft-report"
          >
            <Button
              color='green'
              className='button-dashboard'
              disabled={selectedPropertyArray.length > 0 ? false : true}
              onClick={draftToggle}
              style={{display: selectedPropertyArray.length > 0 ? 'inline-block' : 'none'}}
            >
              <Icon icon='save' />{' '}
              Save As Draft
            </Button>
          </div>

          <div
            style={{ position: 'absolute', marginLeft: '199px' }}
            className='table-tabs-button w-auto d-block d-sm-none'
            id="Tooltip-draft-report"
          >
            <Button
              color='green'
              className='button-dashboard'
              disabled={selectedPropertyArray.length > 0 ? false : true}
              onClick={draftToggle}
              style={{display: selectedPropertyArray.length > 0 ? 'inline-block' : 'none'}}
            >
              <Icon icon='save'/>{' '}
              Save As Draft
            </Button>
          </div>
        </>
        }

          <Modal isOpen={draftModal} toggle={draftToggle} modalClassName='mt-5'>
            <ModalHeader toggle={draftToggle}>Title for draft report</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md='12' className='mt-3'>
                    <Input
                      type='text'
                      value={draftTitle}
                      onChange={(e) => setDraftTitle(e.target.value)}
                      placeholder='Title'
                    />
                    {titleError && !draftTitle && (
                      <p className='input-error'>Title is required.</p>
                    )}
                  </Col>
                </Row>
              </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleDraftReport}>
                Save
              </Button>{' '}
              <Button color="secondary" onClick={draftToggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          
					{	selectedPropertyArray.length === 0 && <Tooltip
							placement="top"
							isOpen={openToolTip}
							autohide={false}
							target="Tooltip-select-properties"
							toggle={() => setOpenToolTip(!openToolTip)}
						>
							Select properties by clicking the ❤ icon next to the community names.
						</Tooltip>}

          <TabContent activeTab={activeTab}>
            <TabPane tabId='List'>
              <Row className='apartment-table'>
                <Col md='12'>
                  <PropertyList
                    // columns={columns}
                    properties={properties}
                    // filterable
                    path={props.path}
                    toggleSelection={toggleSelection}
                    setSidebarState={setSidebarState}
                    setProperty={setProperty}
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                    selectedPropertyArray={selectedPropertyArray}
                    setSelectedPropertyArray={setSelectedPropertyArray}
                    paginationInfo={paginationInfo}
                    setPaginationInfo={setPaginationInfo}
                    setShouldSearch={setShouldSearch}
                    setOrderBy={setOrderBy}
                    // changeIsChecked={(isChecked) => setIsChecked(isChecked)}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId='Map'>
              <Row>
                <Col sm='12'>
                  <Card body className='p-lg-0 pl-lg-3'>
                    {properties.length > 0 ? (
                      <PropertyMap
                        getFilter={getFilter}
                        setFilter={setFilter}
                        setShouldSearch={setShouldSearch}
                        setShouldMapSearch={setShouldMapSearch}
                        changeCheckedProperty={(checkedProperty) =>
                          setcheckedProperty(checkedProperty)
                        }
                        propertySelection={selectedProperties}
                        removePropertySelection={(property) =>
                          togglePropertySelection(property)
                        }
                        setSidebarState={setSidebarState}
                        setProperty={setProperty}
                        properties={properties}
                        path={props.path}
                        paginationInfo={paginationInfo}
                        setPaginationInfo={setPaginationInfo}
                        mapMarkers={[...selectedPropertyArray, ...mapMarkers]}
                        selectedPropertyArray={selectedPropertyArray}
                        setSelectedPropertyArray={setSelectedPropertyArray}
                        toggleSelection={toggleSelection}
                        activeTab={activeTab}
                        setMapLoaded={setMapLoaded}
                        mapLoaded={mapLoaded}
												setSelectedPropertiesFromMap={setSelectedPropertiesFromMap}
                      />
                    ) : null}
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
}